<template>
  <div>
    <div>
      <h1
        class="header-text pb-3"
        ref="headerEditProfile"
        aria-labelledby="header for Mentor Survey Scores page"
        data-cy="header-admin-reports-mentor-scores"
        autofocus
      >
        Mentor Survey Scores
      </h1>
      <div class="text-right flex-auto py-6">
        <Button class="" text="Download Data" @click="downloadData('mentor_survey_scores.csv', records)" />
      </div>
    </div>
    <div class="box-style mb-6">
      <div v-if="isLoading">
        <LoadingSpinner />
      </div>
      <div v-else>
        <dynamic-table
          :records="this.records"
          :fields="tableConfig"
          no-data-msg=""
          striped
          hover
        >
        </dynamic-table>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
import DynamicTable from "@/components/displays/admin-displays/DynamicTable";
import { get } from "@/composables/httpUtil";
import { downloadData } from "@/composables/downloadUtils";
import { mapActions, mapGetters } from "vuex";
import Button from "@/components/reusable-components/base-level-components/Button";

export default {
  name: "Pairings",
  components: { LoadingSpinner, DynamicTable, Button },
  data() {
    return {
      isLoading: true,
      records: [],
      tableConfig: [
        {
          display: "Prefix",
          name: "prefix",
          sortable: false,
          class: "",
        },
        {
          display: "Mentor First",
          name: "mentor_first",
          sortable: false,
          class: "",
        },
        {
          display: "Mentor Middle",
          name: "mentor_middle",
          sortable: false,
          class: "",
        },
        {
          display: "Menotr Last",
          name: "mentor_last",
          sortable: false,
          class: "",
        },
        {
          display: "Score",
          name: "score",
          sortable: false,
          class: "",
        },
        {
          display: "Student First",
          name: "student_first",
          sortable: false,
          class: "",
        },
        {
          display: "Student Middle",
          name: "student_middle",
          sortable: false,
          class: "",
        },
        {
          display: "Student Last",
          name: "student_last",
          sortable: false,
          class: "",
        },
        {
          display: "Term Name",
          name: "term_name",
          sortable: false,
          class: "",
        },
      ],
    };
  },
  mounted() {
    this.fetchLookupResource().then(() => {
      this.getData();
    });
  },
  methods: {
    downloadData,
    getData() {
      let url = `admin/reports/mentor_scores`;
      get(url)
        .then((results) => {
          this.records = results;
        })
        .catch((error) => {
          console.log("error in catch statement", error);
          this.records = [];
        })
        .finally(() => (this.isLoading = false));
    },
    ...mapActions(["fetchLookupResource"]),
  },
  computed: {
    ...mapGetters(["lookup_current_term"]),
  },
};
</script>

<style scoped></style>
